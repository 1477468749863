import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { RemoteLibraryService } from 'remote-library';
import html2canvas from 'html2canvas';


// Objeto de partes por tipo de vehiculo
const VEHICLES_PARTS = {
  car: {
    front: false,
    bonnet: false,
    windscreen: false,
    roof: false,
    rearwindow: false,
    rear: false,
    frontwingleft: false,
    frontwingright: false,
    leftfrontdoor: false,
    rightfrontdoor: false,
    leftreardoor: false,
    rightreardoor: false,
    rearwindowLeft: false,
    rearwindowRight: false,
  },
  moto: {
    frontwheel: false,
    windscreen: false,
    controlpanel: false,
    seat: false,
    rearwheel: false,
    handlebarleft: false,
    leftside: false,
    leftpipe: false,
    handlebarright: false,
    rightside: false,
    rightpipe: false
  },
  truck: {
    front: false,
    windscreen: false,
    roof: false,
    fronttray: false,
    backtray: false,
    rear: false,
    leftfrontcorner: false,
    rightfrontcorner: false,
    leftcabdoor: false,
    rightcabdoor: false,
    leftfrontwheel: false,
    rightfrontwheel: false,
    leftbackwheel: false,
    rightbackwheel: false
  },
  pickup: {
    front: false,
    bonnet: false,
    windscreen: false,
    roof: false,
    cargobed: false,
    rear: false,
    leftfrontwheel: false,
    rightfrontwheel: false,
    leftcabdoor: false,
    rightcabdoor: false,
    leftcargobed: false,
    rightcargobed: false,
    leftbackwheel: false,
    rightbackwheel: false
  }
};


@Component({
  selector: 'app-vehicle-parts',
  templateUrl: './vehicle-parts.component.html',
  styleUrls: ['./vehicle-parts.component.scss']
})
export class VehiclePartsComponent implements OnInit {

  // Propiedades relacionada a la vista
  public loading: boolean;
  public partVals: {key: boolean};
  public nextAvailable: boolean;
  @ViewChild('myForm')
  public myForm: NgForm;
  @ViewChild('vehiclePartsContainer')
  public vehiclePartsContainer;

  // Objetos de data sobre componente
  vehicleParts;
  actualSelfadjust;

  // Logica de componente
  vehiclePartsImage: string;
  checkedArr: boolean[];
  vehicleType: string;
  unfixed: boolean;
  openNotification: Boolean = false;


  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.remoteService.selfAdjustService.changeLabels();
    this.remoteService.selfAdjustLanguageService.browserLanguage();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.vehicleParts = this.remoteService.selfAdjustService.myPage('vehicle-parts');
    if (!this.vehicleParts) {
      this.vehicleParts = this.remoteService.selfAdjustService.myPage('car-parts');
    }
    if (this.vehicleParts.reopen) {
      this.unfixed = true;
    }
    this.nextAvailable = !this.vehicleParts.mandatory;
    this.setVehicleType();
    this.updatePage(this.actualSelfadjust);
  }

  /**
   * @description Funcion para actualizar el componente con la informacion traida de la BDD
   * @param data Objeto de informacion del autoajuste
   */
  updatePage(data) {
    this.checkedArr = [];
    for (const part in data.vehicleParts) {
      if (data.vehicleParts[part]) {
       this.partVals[part] = data.vehicleParts[part];
       this.checkedArr.push(true);
       this.nextAvailable = true;
      }
    }
    if (this.myForm) {
      this.myForm['_directives'].forEach(e => {
        if (data[e.name]) {
          e._updateValue(data[e.name]);
        }
      });
    }
  }

  /**
   * @description Funcion para registrar una pieza seleccionada y afectar los flags correspondientes
   * @param part
   */
  checked(part) {
    if (this.vehicleParts.reopen) {
      this.unfixed = false;
    }
    this.partVals[part] = !this.partVals[part];
    if (this.partVals[part]) {
      this.checkedArr.push(true);
    } else {
      this.checkedArr.pop();
    }
    if (this.checkedArr.length >= 1) {
      this.nextAvailable = true;
    } else {
      this.nextAvailable = false;
    }
  }

  /**
   * @description Funcion para establecer el tipo de vehiculo que se utilizara para el componente
   */
  setVehicleType() {
    this.vehicleType = this.actualSelfadjust.vehicleType;
    if (!this.vehicleType) {
      this.vehicleType = 'car';
    }
    this.partVals = VEHICLES_PARTS[this.vehicleType];
    if (!this.vehicleParts) {
      throw new Error('Unrecognized Vehicle Type');
    }
  }

  /**
   * @description Funcion para hacer submit de la imagen capturada sobre le vehiculo con sus partes seleccionadas.
   * Si esta funcion va bien, llama a movePage('next').
   */
  async submitVehicleParts() {
    this.loading = true;
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    html2canvas(this.vehiclePartsContainer.nativeElement, {} as any).then(canvas => {
      this.vehiclePartsImage = canvas.toDataURL('image/jpeg', 0.7);
      const contentType = this.vehiclePartsImage.slice(this.vehiclePartsImage.indexOf('image/'), this.vehiclePartsImage.indexOf(';'));
      const caption = 'Damaged parts';
      this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust['id'], 'jpeg').subscribe(res => {
        this.remoteService.selfAdjustService.bucketImage(this.vehiclePartsImage, res['media_url'], contentType).subscribe((result: any) => {
          this.remoteService.selfAdjustService.addImage(this.actualSelfadjust['id'], this.actualSelfadjust['securityKey'],
            res['media_id'], 'vehicleParts', 'jpeg', undefined, coordinates, caption).subscribe(e => {
              this.movePage('next');
            },
            err => {
              console.log('Error in addImage', err);
              this.showNotification();
            });
        },
        err => {
          console.log('Error in bucketImage', err);
          this.showNotification();
        });
      },
      err => {
        console.log('Error in getMediaUrl', err);
        this.showNotification();
      });
    });
  }

  /**
   * @description Funcion para navegar en cualquier direccion, dependiendo del parametro de entrada.
   * Tambien hace una peticion a backend para log de eventos y guardado de informacion de formulario.
   * @param action action = 'next' para avanzar al siguiente componente. Cualquier otro valor para retroceder
   */
  movePage(action) {
    if (action === 'next') {
      const data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.vehicleParts.reopen ? this.remoteService.selfAdjustService.AppStatus.reopened :  this.remoteService.selfAdjustService.AppStatus.inprocess,
        fields2Update: {
          vehicleParts: this.partVals,
        },
        logInfo: {
          component: 'moto-parts',
          action: 'continue'
        }
      };

      if (this.vehicleParts.selfadjustFields) {
        this.myForm['_directives'].forEach(e => {
          data.fields2Update[e.name] = e.value;
        });
      }
      this.remoteService.selfAdjustService.pushData(data).subscribe(res => {
        this.remoteService.selfAdjustService.actualSelfAdjust.vehicleParts = this.partVals;
        if (this.vehicleParts.selfadjustFields) {
          this.myForm['_directives'].forEach(e => {
            this.remoteService.selfAdjustService.actualSelfAdjust[e.name] = e.value;
          });
        }
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      },
      err => {
          console.log('Error in selfadjust-update', err);
          this.showNotification();
      });
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }

  /**
   * @description Funcion para obtener la ruta de la imagen que muestra el diagrama del vehiculo
   */
  getVehicleImg = () => `../../assets/images/logo/${this.vehicleType}-parts.png`;

  /**
   * @description Funcion para mostrar notificaciones
   */
  showNotification() {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      this.cd.detectChanges();
    }, 5000);
    this.loading = false;
    this.cd.detectChanges();
  }

  /**
   * @description Importacion de estilos personalizados de empresa.
   * @param customStylesFolder
   */
  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }
}
